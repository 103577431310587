/* Auth slider css */
.auth-slider-heading {
    color: #000;
    font-family: 'Outfit', system-ui;
    letter-spacing: 1px;
    text-transform: capitalize;
    background: linear-gradient(to right, #359be4, #BE51FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2.3rem;
    font-weight: 600;
    line-height: 1.2;
  }
  .slick-dots {
    position: relative;
    z-index: 9999; /* or any value you need */
  }
  .auth-slider-description {
    font-size: 1rem;
    letter-spacing: 0.04rem;
    font-family: 'Outfit', system-ui;
    color: #000;
}
/* video.forgotSilder {
  filter: hue-rotate(190deg);
} */
.MuiInputBase-root.slect-package-discount {
  width: 50px;
  font-size: 12px !important;
  position: relative;
  background: transparent;
}
.MuiInputBase-root.slect-package-discount .MuiSelect-select.MuiSelect-outlined {
  padding-right: 20px !important;
  background: transparent !important;
  
}
.MuiInputBase-root.slect-package-discount .MuiSelect-select.MuiSelect-outlined svg.MuiSvgIcon-root {
  width: 1.1rem;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded ul li.MuiButtonBase-root svg.MuiSvgIcon-root {
  font-size: 1rem;
}
.MuiInputBase-root.slect-package-discount svg.MuiSvgIcon-root {
  position: relative;
  right: -5px;
  top: -1px;
}
.flex-wraps{
  display: flex;
}
.flex-wraps .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-adornedEnd {
  padding-right: 0 !important;
  
}

.MuiInputBase-root.slect-package-discount input.MuiSelect-nativeInput.css-yf8vq0-MuiSelect-nativeInput {
  height: 30px;
  width: 52px;
}
.invoice-logo-container {
  position: relative;
  left: 48%;
  top: 55px;
}
.MuiInputBase-root.slect-package-discount svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
  z-index: 0;
  position: absolute;
  right: 0;
  top: 7px;
}
thead.MuiTableHead-root.invoiving-table-head{
  background: #e3f2fd;
}
tbody.MuiTableBody-root.invoiving-table-body {
  border: 1px solid #e3e8ef;
}
.span-block{
  display: block;
}

.customButton {
  background: #9469FC;
  border: none;
  color: white;
  padding: 8px 20px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.customButton:hover {
  background: #c2acf8;
  color: black;
}

.customButton:disabled {
  background: rgb(209, 209, 209);
  color: gray;
  cursor: not-allowed;
}

.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}