.track-inner {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    background-color: #f9f7ff;
    position: relative;
    padding-bottom: 1em;
}
.static-banner h2 span {
    font-size: 2rem;
    font-weight: 500;
}
.track-nw {
    padding: 0em 1em 0em 1em;
    margin-bottom: 5em;
}
.common-spacing {
	margin: 15px 0px;
}
.track-top {
    background-color: #ffffff;
    padding: 15px;
    text-align: center;
    border: 1px solid #e1e1e1;
}
.logo img {
    background: #fff;
    max-width: 83px;
    padding: 11px 13px;
    border: 2px solid #ccc;
    box-shadow: 0px 2px 6px #ccc;
}
.merchant-name {
    color: #494747;
    font-size: 20px;
    font-weight: 400;
    margin: 10px 0 5px;
}
.view-btn {
    font-weight: 600;
    color: #fff !important;
    border-bottom: 1px solid #fff;
    margin: 5px 0px 20px 0px;
    display: inline-block;
    font-size: 16px;
}
.track-center {
    padding: 15px 15px;
    border: 1px solid #e1e1e1;
    border-top: none;
    margin-bottom: -15px;
}
.feedback-dv {
    border-radius: 20px;
    border: 1px solid #E2E2E2;
    background-color: #fff;
    padding: 15px;
    position: relative;
    /* margin-top: 25px; */
}
.feedback-dv h2 {
    font-weight: 600;
    font-size: 17px;
}
.feedback-dv h2 span {
    font-weight: 400;
    display: block;
}
.feedback-dv .star {
    width: 100%;
    max-width: 120px;
}
.feedback-img-dv {
    position: absolute;
    margin: 0 -50px;
    width: 150px;
    bottom: -15px;
    right: 58px;
}
.tax-invoice-dv {
    border-radius: 20px;
    border: 1px solid #E2E2E2;
    background-color: #fff;
    padding: 0px 15px;
    position: relative;
    margin-top: 25px;
    text-align: center;
}
.text-heading {
    font-size: 17px;
    font-weight: 700;
}
.text-para {
    font-size: 14px;
    width: 100%;
    max-width: 310px;
    margin: 0 auto;
    line-height: normal;
}
.tax-invoice-dv > div {
    border-bottom: 1px dashed #C0C0C0;
    padding: 15px 0;
}
.tax-invoice-dv > div:last-child {
    border-bottom: none;
}

/*   view detail screen   */
.view-track-inner {
    position: relative;
    border: 1px solid #e6e6e6;
    background: #fff;
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.view-track-inner-track-top {
    display: flex;
        justify-content: flex-start;
        padding: 0 20px;
        gap: 25px;
}
.view-track-inner-track-top h5#merchant-name {
    color: #000;
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin: 15px 0 0 0;
}
.view-track-inner p.text-para {
    max-width: 100%;
}
.view-track-inner .track-center {
    padding: 0 20px 15px;
}
.view-track-inner-track-top .logo img {
    max-width: 100% !important;
    width: 130px !important;
    border: 4px double #ccc;
    padding: 0 0;
    margin: 0px 0 0 0;
    position: relative;
    border-radius: 0 !important;
    top: 19px;
}
.view-track-inner .tax-invoice-dv {
    border: 0;
    padding: 9px 17px 0 13px;
    border-top: 0px solid #eae5e5;
    border-radius: 0;
    margin: 22px -20px 0 -20px;
}
.tax-invoice-dv div.MuiGrid-root.MuiGrid-container {
    width: 100% !important;
    margin: -16px 0 0 0 !important;
}
.badge-product span.MuiBadge-badge {
    right: -27px;
}
.table-view-cont {
    border-radius: 0 !important;
}