.new-create-invoice-wrapper {
    width: 100%;
    height: 87vh;
    background: white;
    border-radius: 5px;
}





/*  */


.cancel:hover {
    background: rgb(209, 209, 209);
    color: black;
}


/* .total-amount-container {
    justify-content: flex-end;
    width: 100%;
    display: flex;
} */

.total-amount {
    background: rgb(237, 231, 246);
    width: fit-content;
    padding: 7px 24px;
    border-radius: 5px;

}

.wallet-balance {
    color: green;
    font-size: 12px;
    font-weight: 700;
    margin-left: 10px;
    padding: 5px 15px;
    border-radius: 5px;
}

.wallet-balance.negative {
    color: red;
}

.grand-amt-fields {
    width: 100%;
    padding: 10px 15px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

span.MuiSwitch-root.MuiSwitch-sizeMedium.percentage-to-inr-switch {
    position: absolute;
    margin: -11px 0 0 0;
    right: 0;
}

.customer-info-checkout-parent-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 11px;
    padding: 20px 15px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.customer-info-checkout-container {
    display: flex;
    gap: 5px;
    padding: 0 0 0 15px;
    align-items: center;
}

.customer-info-checkout-container span:first-child {
    font-weight: 600;
}

.customer-items-in-cart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
    text-align: left;
    margin-top: 10px;
}

.customer-items-in-cart label {
    font-weight: 600;
}

.checkout-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.checkout-item .item-number {
    color: #000;
    margin-right: 5px;
}

.customer-items-in-cart h4 {
    margin: 0 auto 5px;
    color: #000;
    padding: 7px 15px;
    width: 100%;
    display: flex;
    text-transform: uppercase;
    font-size: .85rem;
    border-radius: 5px;
}

.checkout-item-container {
    padding: 0 0 0 15px;
    width: 100%;
}

.checkout-image img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.customButton.checkout-button {
    position: absolute;
    bottom: 20px;
    right: 10px;
    width: 15rem !important;
}

.accordian-container {
    border: 1px solid #e3e3e3;
    height: 100%;
    margin-top: 1rem;
    border-radius: 5px;
}

.invoice-table-container {
    height: 65vh;
    padding: 5px 5px 5px 10px;
}

.proceed-to-payment-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #EDE7F6;
    padding: 5px;
    border-radius: 5px;
    width: 95%;
    margin-bottom: 24px;
}

.proceed-to-payment-div h3 {
    padding: 0;
    margin: 0;
}


.pl-2 {
    padding-left: 2px !important;
}

.pt-4 {
    padding-top: 4px !important;
}

.mt-4 {
    margin-top: 4px !important;
}

.pointer {
    cursor: pointer;
}

.vertical-line {
    width: 1px;
    height: 100vh;
    background-color: #e3e3e383;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.payment-div input {
    background-color: white !important;
}

.payment-div {
    margin: 14px 0px;
}

.create-invoice-button-div {
    position: absolute;
    bottom: 10px;
    right: 5px;

}

.customButton.pending {
    background: #E68E8F;
}

.create-account-button {
    height: 50px;
    width: 98%;
    border: 1px solid #121926;
    color: #121926;
    background: white;
    border-radius: 5px;
    font-weight: 600;
    margin: 0 auto;
    cursor: pointer;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.useful-tips img.appointment-text-field-start-adornment {
    width: 9rem;
    margin: 15px auto;
    display: block;
}
ul.useful-tips-ul {
    padding: 0 0 0 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
ul.useful-tips-ul li {
    position: relative;
    margin: 0 0 0px 0;
    padding: 0 0 6px 0;
}

ul.useful-tips-ul li::after {
    background: #ebebeb;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    content: "";
    left: -14px;
}
ul.useful-tips-ul li:last-child:after{
    display: none;
}