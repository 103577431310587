@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  .wrap-services-staff-list {
    max-height: 35vh;
  }
  .wrap-propfile-info-test {
    width: 33% !important;
  }
  .wrap-propfile-info-test:last-child {
    width: 66% !important;
  }
}
@media screen and (max-width: 1920px) {
  .wrap-propfile-info-test {
    width: 33% !important;
  }
}
@media only screen and (min-width: 1365px) and (max-width: 1640px) {
  .main-auth-wrap.login-wrap .auth-form-container {
    width: 35%;
  }
  .main-auth-wrap.login-wrap .wrapper-logo {
    width: 65%;
  }
  form.login-form {
    width: 28vw;
  }
  .manage-staff-container,
  .employee-info-wrap {
    height: 71vh;
  }
  .wrap-propfile-info-test {
    width: 33% !important;
  }
  .wrap-propfile-info-test:last-child {
    width: 66% !important;
  }
  .costumer-desing-details {
    width: 30%;
  }
  .custom-input-label,
  .container-signup .country-phone-wrap label {
    font-size: 11.5px !important;
  }
  .Container-container {
    height: 490px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-auth-wrap.login-wrap .auth-form-container {
    width: 38%;
  }
  .main-auth-wrap.login-wrap .wrapper-logo {
    width: 62%;
  }
  form.login-form {
    width: 28vw;
  }
  form.login-form span.login-your-account {
    font-size: 21px;
    display: block;
  }
  .custom-input-label {
    font-size: 12px;
  }
  .custom-input-label,
  .container-signup .country-phone-wrap label {
    font-size: 11.5px !important;
  }
}
.auth-form-container form {
  width: 94%;
}
@media screen and (min-width: 1025px) and (max-width: 1199px) {
  .main-auth-wrap {
    flex-wrap: wrap;
    flex-direction: row;
    width: 100% !important;
  }
  .left-wrapper-container {
    margin: 0.5rem 3rem 0px 3rem !important;
  }
  .logo-div.public-wrapper img {
    width: 2.8rem;
  }
  .container-signup .login-your-account {
    font-size: 20px !important;
  }
  .custom-button {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .main-auth-wrap {
    flex-wrap: wrap;
    flex-direction: row;
    width: 100% !important;
  }
  .left-wrapper-container {
    margin: 0.5rem 3rem 0px 3rem !important;
  }
  .logo-div.public-wrapper img {
    width: 2.8rem;
  }
  .container-signup .login-your-account {
    font-size: 19px !important;
  }
  .custom-button {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 581px) and (max-width: 767px) {
  .main-auth-wrap {
    flex-wrap: wrap;
    flex-direction: row;
    width: 100% !important;
    overflow-y: scroll;
  }
  .left-wrapper-container {
    margin: 0.5rem 2rem 0px 2.5rem !important;
  }
  .logo-div.public-wrapper img {
    width: 2.5em;
  }

  .container-signup .login-your-account {
    font-size: 18px !important;
  }
  .custom-button {
    font-size: 14px !important;
  }
  .auth-form-container {
    margin: 0px 15px;
  }
  .invoice-logo-container{
    top: 100px;
  }
}
@media screen and (min-width: 320px) and (max-width: 580px) {
  .main-auth-wrap {
    flex-wrap: wrap;
    flex-direction: row;
    width: 100% !important;
    overflow-y: scroll !important;
    z-index: 2;
  }
  .left-wrapper-container {
    margin: 2rem 1rem 0px 2.5rem !important;
  }
  .logo-div.public-wrapper img {
    width: 2em;
  }
  .container-signup h1.login-your-account {
    font-size: 16px !important;
    font-weight: 600;
    margin-top: 33px;
  }
  .login-field-required {
    font-size: 16px;
  }
  .link-login span {
    font-size: 15px;
    font-weight: 500;
  }
  .custom-button {
    font-size: 12px !important;
  }
  .auth-form-container {
    margin: 100px 15px;
  }
  .two-field-form {
    display: block !important;
  }
  .wrapper-logo {
    margin-top: -11px;
    z-index: 4;
    font-size: 20px;
    background: #000;
  }
  .logo-div.public-wrapper h3 {
    font-size: 22px;
    font-weight: 600;
  }
  .logo-div.public-wrapper {
    width: 17em;
    position: relative;
    z-index: 1;
    position: relative;
    justify-content: flex-start;
    top: -10px;
}
.invoice-logo-container{
  top: 100px;
}
}
