/* HelpAndSupportButton.css */

.help-support-button {
    background-color: #EDE7F6;
    border: none;
    border-radius: 50%;
    /* Round button */
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
    margin-left: 1rem;
}

.help-support-button:hover {
    background-color: #4a2480;
    /* Darker shade on hover */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    /* Hover shadow */
}

.help-support-button:focus {
    outline: none;
    /* Remove outline when focused */
}

.help-support-button-icon {
    font-size: 24px !important;
    color: "#4a2480"
}

.help-support-button-icon:hover {
    color: #EDE7F6;
}